

.form-item-field-resource-topics-target-id{
    display: none;
    background-color: aquamarine;
}

.drupal-search-form label {
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 80%;
  font-weight: 400;
}


.drupal-search-form .taa-form-wrapper {
  display: flex;
  align-items: flex-end;
  margin-bottom: 4rem;
  @extend .input-group;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
}

.drupal-search-form .form-item-name,
.drupal-search-form .form-item-title {
  flex: 1 1;
  input {
    @extend .form-control;
    position: relative;
    flex: 1 1 auto;
    margin-bottom: 0;
    width: 100%;
    @media screen and (min-width: 768px) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.drupal-search-form .form-actions {
  margin-left: -1px;
  display: flex;
  position: relative;
  &::before {
    content: $magnifying-glass;
    display: block;
    height: 0.8em;
    width: auto;
    height: 1em;
    width: 1em;
    position: absolute;
    left: 11px;
    bottom: 50%;
    transform: translateY(42%) scale(0.75);
    z-index: 3;
    @extend .filter-white;
  }
  input {
    @extend .btn;
    @extend .btn-primary;
    cursor: pointer;
    position: relative;
    z-index: 2;
    border-top-left-radius: 0;
    width: 100%;
    height: 100%;
    padding-left: 38px;
    -webkit-appearance: button;
    @media screen and (max-width: 767px) {
      border-top-right-radius: 0;
    }
    @media screen and (min-width: 768px) {
      border-bottom-left-radius: 0;
    }
  }
}
