$w: 467;
$h: 733.82;

.nations {
  position:relative;
  width: 100%;
  padding-bottom: $h / $w * 100%;
  &:hover {
    .nation {
      opacity: 50%;
    }
  }
}
.nation {
  display:block;
  position: absolute;
  background-size:contain;
  
  transition: opacity 0.5s ease;
  &:hover {
    opacity: 100% !important;
    transition: opacity 0.5s ease;
  }
}
#map-scotland {

  width:276.88 / $w * 100%;
  height: 409.63 / $h * 100%;
  
  left: 24.14 / $w * 100%;
  top: 0.46 / $h * 100%;
  background-image: url(#{$image-path}backgrounds/nations/scotland.png);
  
}
#map-northern-ireland {

  width:128.47 / $w * 100%;
  height: 104.44 / $h * 100%;

  left: 0.59 / $w * 100%;
  top: 354.67 / $h * 100%;
  background-image: url(#{$image-path}backgrounds/nations/northern-ireland.png);

}
#map-england {

  width:382.09 / $w * 100%;
  height: 459.87 / $h * 100%;

  left: 84.66 / $w * 100%;
  top: 313.95 / $h * 100%;
  background-image: url(#{$image-path}backgrounds/nations/england.png);

}
#map-wales {

  width:133.06 / $w * 100%;
  height: 157.26 / $h * 100%;

  left: 126.67 / $w * 100%;
  top: 506.02 / $h * 100%;
  background-image: url(#{$image-path}backgrounds/nations/wales.png);

}


.bg-img-scotland {
  background-image: url(#{$image-path}backgrounds/nations/scotland-background.png);
}

.bg-img-wales {
  background-image: url(#{$image-path}backgrounds/nations/wales-background.png);
}

.bg-img-england {
  background-image: url(#{$image-path}backgrounds/nations/england-background.png);
}

.bg-img-northern-ireland {
  background-image: url(#{$image-path}backgrounds/nations/northern-ireland-background.png);
}

.bg-img-england, .bg-img-wales, .bg-img-northern-ireland, .bg-img-scotland {
  background-size: contain;
  background-repeat:no-repeat;
  padding-top: 8%;
}